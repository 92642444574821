<template>
  <div class="login">
    <h3>用户登录</h3>
    <div class="flex justify-space-between">
    <el-form :model="loginInfo" ref="loginInfo" :label-position="right" :rules="rules" label-width="80px" style="width:100%">
        <el-form-item label="登录账号" prop="username">
            <el-input v-model="loginInfo.username"/>
        </el-form-item>
        <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="loginInfo.password" autocomplete="off"/>
        </el-form-item>
    </el-form>
    </div>
    <div class="flex justify-space-between">
        <el-button type="primary" @click="userLogin" style="width: 120px;">登录</el-button>
        <el-button  type="text" @click="toRegister">用户注册</el-button>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { Document, Menu as IconMenu, Location, Setting,} from '@element-plus/icons-vue'
export default {
  name: 'UserLogin',
  data () {
    return {
        loginInfo: {
            username: '',
            password: ''
        },
        rules: {
            username: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
            password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
        }
    }
  },
  methods: {
    toRegister() {
        this.$router.push('/userRegister');
    },
    userLogin() {
        this.$refs.loginInfo.validate((valid) => {
          if (valid) {
            var param = {};
            param.username = this.loginInfo.username;
            param.password = this.loginInfo.password;
            this.$http.post(this.$reqURL.userLogin, this.$qs.stringify(param)).then(response => {
                if  (response.data.retCode == 1) {
                    var userInfo = response.data.data;
                    ElMessage.success('登录成功');
                    this.$store.commit('setToken', userInfo.token)
                    this.$router.push('/noteCatalog');
                } else {
                    ElMessage.error('账号或密码错误')
                }
            });
          } else {
            ElMessage.error('登录信息有误');
          }
        });
    }
  }
}  
</script>

<style>
.login {
    display:flex;
    flex-direction: column;
    justify-content: center;
    font-size:14px;
    text-align: center;
    width: 12%;
    min-width: 200px;
    margin-left: 41%;
    margin-top: 15%;
    padding: 0 3% 1% 3%;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
}
</style>
