import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from './utils/store.js'
import router from './route/index'
/*
* 前置拦截，在请求头中设置token
*/
axios.interceptors.request.use(config => {
  // 可以统一设置请求头
  var token = localStorage.getItem('token')
  if (token) {
      config.headers.token = token;
  }
  return config
})
/*
* 后置拦截，处理登录过期场景
*/
axios.interceptors.response.use(
    res => {
        return res;
    },
    error => {
        // 根据请求状态觉得是否登录或者提示其他
        if (error.response && error.response.status === 401) {
            store.commit('logout');
            router.push({path: '/userLogin'});
            ElMessage.error('登录过期，请重新登录');
        } else if (error.response.status === 403) {
            ElMessage.error('权限不足，无法访问');
        } else {
            ElMessage.error('服务异常：' + error.response.status);
        }
        return Promise.reject(error)
    }
)

