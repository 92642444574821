import { createRouter , createWebHistory} from "vue-router";
const app = () => import("@/App.vue")
const userLogin = () => import("@/components/UserLogin.vue")
const userRegister = () => import("@/components/UserRegister.vue")
const noteCatalog = () => import("@/components/Note/NoteCatalog.vue")
const noteInfo = () => import("@/components/Note/NoteInfo.vue")

const routes = [
  { 
    path: "/", 
    redirect: "/userLogin" 
  },
  {
    path: "/noteCatalog",
    name: "noteCatalog",
    component: noteCatalog
  },
  {
    path: "/noteInfo",
    name: "noteInfo",
    component: noteInfo
  },
  {
    path: "/userLogin",
    name: "userLogin",
    component: userLogin
  },
  {
    path: "/userRegister",
    name: "userRegister",
    component: userRegister
  },
]

export const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    // mode: 'history',
    routes: routes
})

export default router