import Vue from 'vue'
import Vuex from 'vuex'
import qs from 'qs'
export const store = new Vuex.Store({
    state: {
        token: '',
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
            localStorage.token = token;
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
            localStorage.userInfo = qs.stringify(userInfo);
        },
        logout(state) {
            localStorage.token = '';
            localStorage.userInfo = qs.stringify('');
            state.token = '';
            state.userInfo = {};
        },
        getToken(state) {
            if (!state.token) {
                state.token = localStorage.getItem('token');
            }
            return state.token;
        }
    },
    getters: {

    }, 
    actions: {

    }
})
export default store