const imgLocalPath = 'http://192.168.31.115:8888/';
const imgServerPath = 'https://www.linhai.online/img/';
export const reqURL = {

    imgBasePath: imgServerPath,

    queryUsernameIsExist: '/api/pmkit/prelogin/queryUsernameIsExist',
    sysuserAdd: '/api/pmkit/prelogin/add',
    userLogin: '/api/pmkit/prelogin/login',

    noteFolderList: '/api/mine/note/folder/list',
    noteFolderAdd: '/api/mine/note/folder/add',
    noteList: '/api/mine/note/list/',
    noteAdd: '/api/mine/note/add',
    noteSave: '/api/mine/note/save',
    notePublish: '/api/mine/note/publish',
    noteContent: '/api/mine/note/content/',
    noteImg: 'api/file/image/editor',
    noteDelete: '/api/mine/note/delete',
    noteHtmlToWord: 'api/file//word/htmlToWord',
    noteHtmlToPdf: 'api/file//pdf/htmlToPdf',
    noteCatalogList: '/api/mine/note/catalog/list',
    noteTempList: '/api/mine/note/temp/',
}
export default reqURL