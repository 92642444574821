<template>
<router-view></router-view>
  <el-row class="tac">
    <!-- <el-col :span="4">
      <h5 class="mb-2">IPM</h5>
      <el-menu default-active="7" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
        <el-sub-menu index="1">
          <template #title>
            <el-icon><location /></el-icon>
            <span>需求系统</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="1-1">item one</el-menu-item>
            <el-menu-item index="1-2">item one</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-menu-item index="2">
          <el-icon><icon-menu /></el-icon>
          <span>设计系统</span>
        </el-menu-item>
        <el-menu-item index="3">
          <el-icon><document /></el-icon>
          <span>进度系统</span>
        </el-menu-item>
        <el-menu-item index="4">
          <el-icon><setting /></el-icon>
          <span>文档系统</span>
        </el-menu-item>
        <el-menu-item index="5">
          <el-icon><setting /></el-icon>
          <span>质量系统</span>
        </el-menu-item>
        <el-menu-item index="6">
          <el-icon><setting /></el-icon>
          <span>跟踪系统</span>
        </el-menu-item>
        <el-menu-item index="7">
          <el-icon><setting /></el-icon>
          <span>知识系统</span>
        </el-menu-item>
      </el-menu>
    </el-col> -->
    <!-- <el-col :span="24">
      <NoteCatalog id="catalog"></NoteCatalog>
      <UserLogin id="login"></UserLogin>
    </el-col> -->
  </el-row>
</template>

<script>
import UserLogin from './components/UserLogin.vue'
import { Document, Menu as IconMenu, Location, Setting,} from '@element-plus/icons-vue'
export default {
  name: 'App',
  components: {
    
  },
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}  
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
