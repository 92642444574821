import Vue from 'vue'
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import qs from 'qs'
import axios from 'axios';
import aa from './axios.js'
import VueAxios from 'vue-axios'
import reqURL from './utils/urls.js'
import store from './utils/store.js'
import App from './App.vue'
import { router } from './route/index'



const app = createApp(App)
app.config.globalProperties.$http = axios;
app.config.globalProperties.$reqURL = reqURL;
app.config.globalProperties.$store = store;
app.config.globalProperties.$qs = qs;
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(VueAxios, axios)
app.mount('#app')
